.title {
    padding: 30px 60px;
    font-weight: 900;
    letter-spacing: 2px;
    background-color: #f8f8f8;
    margin-bottom: 100px;
    background-image: url('../../img/bg-hero.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
}

.title::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.91);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}



.title h1 {
    font-size: 50px;
}

.title h2 {
    font-size: 40px;
    margin: 0;
}

.home-hr {
    width: 1400px;
    margin-bottom: 100px;
}

.hero {
    padding: 20px 50px;
    gap: 30px;
}


.hero-column {
    flex: 1;
    margin-bottom: 200px;
}

.hero-column img {
    width: 70%;
   /*  filter: drop-shadow(10px 10px 5px rgba(0, 0, 0, 0.2)); */
}

.hero-txt {
    line-height: 35px;
    padding: 0 50px;
    box-shadow: 0 3px 41px 0 rgba(0, 0, 0, .25);
    background-color: #f8f8f8;

}

.hero-txt p {
    font-size: 27px;
    letter-spacing: 2px;
}

.hero-txt a {
    font-size: 20px;
}

.hero-txt-link {
    padding-top: 50px;
    gap: 5px;
}


.hero-txt-icon {
    padding-top: 2px;
}

@media screen and (max-width:678px) { 
    .hero {
        flex-direction: column;
        padding: 20px;
        margin-bottom: 200px;
        gap: 60px;
    }
    .hero-column {
        margin-bottom: 0;
    }

    .hero-column img {
        width: 90%;
    }
}