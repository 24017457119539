
.stack-container {
    padding-bottom: 30px;
}

/* .stack-container {
    background-attachment: fixed;
    background-image: url('../../img/bg-hero.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
    margin-bottom: 150px;
    z-index: 1;
    padding-bottom: 30px;
}

.stack-container::before {
    content: "";
    width: 100%;
    height: 100%;
 
     background-color: rgba(255, 255, 255, 0.91);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
} */

 .skills {
    background-attachment: fixed;
    background-image: url('../../img/bg-hero.jpg');
    background-size: cover;
    background-position: center;
    position: relative;
    margin-bottom: 150px;
    z-index: 1;
    padding-bottom: 40px;
}

.skills::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.91);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
} 

.skills-container {
    gap: 50px;
    /* padding: 0 50px; */
    margin-inline: 50px;
    margin-bottom: 80px;
}
.skills-column{
    flex: 1;
   /*  background-color: rgb(191, 235, 148); */
   background-color: rgba(0, 0, 0, 0.56);
    gap: 100px;
    border-radius: 10px;

}




.skills-column:hover {
    box-shadow: 0 3px 41px 0 rgba(0, 0, 0, .25);
}

.box-icon, .skills-txt {
    border: 1px solid black;
    background-color: #f8f8f8;
    width: 100%;
    display: flex;
    justify-content:center;
    align-items: center;


}

.box-icon {
    gap: 30px;
    padding: 15px 0;
    border-radius: 10px 10px 0 0;
}

.skills-txt {
    padding: 20px 0;
    border-radius:0 0 10px 10px;
}

.skills-txt h4 {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
}

#trello{
    background-color: #406365;
    padding-inline: 3px;
    color: white;
}

.box-skills {
    margin-inline: 50px;
    margin-bottom: 100px;
    

}


.box-skills ul{
   padding: 0;
}

.box-skills div {
    box-shadow: 0 3px 41px 0 rgba(0, 0, 0, .25);
    padding: 10px 20px;
    background-color:#f8f8f8 ;
}


.stack {
    padding: 30px;
}

.wp {
    padding-left: 300px;
}

.content {
    padding-right: 300px;
}


@media screen and (max-width:678px) { 
   
    .skills-container {
       flex-direction: column;
       gap: 30px;
       margin-inline:20px;
    }

    .wp, .content {
        padding: 0;
    }
}