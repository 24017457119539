.about-card {
    background-image: url("../../img/bg-card.jpg");
    padding: 20px;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    margin: 100px 0 0 50px;
}

.about-card::before {
content: "";
background-color: rgba(186, 107, 10, 0.345); 
position: absolute;
width: 100%;
height: 100%;
top:0;
left: 0;
z-index: -1;
}

.about-card button {
    background-color: #f8f8f8;
}

.about-card button:hover {
    border: 2px solid rgba(0, 0, 0, 0.297);
}

.info-card {
    gap: 80px;
    margin-bottom: 10px;
}

.info-card  img {
    width: 250px;
    background-color: #f8f8f8;
    filter: drop-shadow(10px 10px 5px rgba(0, 0, 0, 0.4));
}

strong {
    text-transform: uppercase;
    font-size: 16px;
}

.info-card-txt {
     width: 267px;
     padding: 15px;
     background-color: #f8f8f8;
     font-size: 20px;
     line-height: 25px;

}

.about-card h4 {
    margin: 0;
}

.about-card button {
    padding: 10px;
    border-radius:10px;
}



.hidden {
    display: none; 
}


.likes {
    background-color: #f8f8f8;
    width: 283px;
    border-radius: 50px 10px 50px 10px;
    padding:40px 20px;
    gap: 20px;
    margin-top: 30px;
}

.likes-title {
    padding: 0 40px;
    font-size: 16px;
    text-transform: uppercase;

}

.likes img {
 width: 30px;
}

.likes div {
    gap: 10px;
}

.weakness {
    padding: 20px 40px;
    border-radius: 10px 50px 10px 50px;
    font-size: 16px;
    margin:50px 0 30px 330px;
    background-color: #f8f8f8;
}
.weakness div {
    gap: 5px;
}

.weakness img {
    width: 35px;
}

.btn-lang {
    margin-bottom: 15px;
}

.btn-lang button {
    padding: 7px;
    width: 34.30px;
}


.devices ul {
    padding: 0;
}

.devices {
    padding:100px 200px;
    gap: 50px;
}

.devices img {
    width: 200px;
}

.devices li {
    padding-bottom: 10px;
}

@media screen and (max-width:678px) { 
   
   .about-card {
    margin:auto;
   }
    .info-card {
    flex-direction: column;
    gap:30px
   }

   .weakness {
    margin:20px 0 0 0;
   }

   .devices {
    flex-direction: column;
    padding: 100px 0;
   }
 
}