
.contacts-column {
    flex:1;
    padding-right: 50px;
}


.contacts-column p {
    padding-inline: 80px;
    font-size: 25px;
    line-height: 40px;
    letter-spacing: 9px;
}

.contacts-txt {
   gap: 50px;

}

.contacts-container {
   /*  margin: auto; */
    padding: 50px;
    gap: 50px;
/*     background-color: rgba(0, 0, 0, 0.8);
    border-radius: 10px; */
}

.contacts-container h5 {
    font-size: 16px;
}

.contacts-container i {
    font-size: 30px;
    color: black;

}


.contacts-section h5 {
    margin-bottom: 5px;
text-transform: uppercase;
}

.contact{
    gap: 25px;
    padding: 20px 20px 40px 20px;
}



.contacts-box, .social-box {
    border-radius: 10px;
    /* border: 2px solid cyan;
   box-shadow: 0 3px 41px 0 rgba(0, 255, 255, .25);  */
    box-shadow: 0 3px 41px 0 rgba(0, 0, 0, .25);
    background-color: #f8f8f8;
    padding-inline: 18px;
}


.contacts-box i {
  /*   border: 2px solid cyan;
    color: cyan; */
    border-radius: 50%;
    padding: 10px;
    background-color: black;
    color: #f8f8f8;
}

i.fa.fa-phone {
    padding: 10px 12px;
}

#wa {
    padding:50px 30px;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: +2;
    
}

#wa i {
    color: #25D366;
    font-size: 50px;
    line-height: 40px;
    
}

.ds {
    border-radius: 50%;
    background-color: #586aea;
    color: white;
    width: max-content;
    padding: 40px;
}


.ds p {
    line-height: 30px;
    letter-spacing: 2px;
}

@media screen and (max-width:678px) { 
    .contacts{
        flex-direction: column;
    }

    .contacts-column {
        padding: 0;
        justify-content: center;
    }
}