.page-title {
    background-image: url(../../img/bg-hero.jpg);
    background-size: cover;
    background-position: center;
    height: 20vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.page-title::before {
    content: "";
    width: 100%;
    height: 100%;
   /*  background-color:rgba(0, 255, 255, 0.8);  */
   background-color: rgba(255, 255, 255, 0.91);
   position: absolute;
    top: 0;
    left: 0;
}

.page-title h2 {
    text-align: center;
    position: relative;
    letter-spacing: 3px;
    font-size: 40px;
    color: black
}
